import consumer from "./consumer"

const exportFilesChannel = consumer.subscriptions.create({ channel: "ExportFilesChannel", user_id: $("meta[name='user-id']").attr('content') }, {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to the export channel!");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Disconnected from the export channel!");
    },

    received(response) {
        const isProcessing = document.cookie.split('; ').find(row => row === `script_executed=${response.data.report_id}`);
        if (isProcessing) { return }

        document.cookie = `script_executed=${response.data.report_id}; expires=${new Date(Date.now() + 5000).toUTCString()}; path=/`;
        if (response.type == 'export_files') {
            const paths = {
                'deliverability_checker': "/admin/deliverability_checkers/:report_id/download_latest_checker_report".replace(':report_id', response.data.report_id),
                'domain': "/admin/postmasters/gpm_domains/:report_id/download_export".replace(':report_id', response.data.report_id)
            }
            window.location.href = paths[response.data.report_type]
        }else if (response.type == 'error') {
            console.log('error')
            alert(response.data.message)
        }
    },
});

export default exportFilesChannel;